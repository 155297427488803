import React from 'react'
import {
  CFView,
  CFImage,
  DefaultScreen,
  MobileScreen,
  CFSelect,
} from 'components'
import {
  mobilePromoMetrotown,
  mobilePromoBroadway,
  mobilePromoCoquitlam,
  mobilePromoSurrey,
  mobilePromoNorthRd,
  promotionsMetrotown,
  promotionsCoquitlam,
  promotionsBroadway,
  promotionsSurrey,
  promotionsNorthRd,
  promotions2,
  mobilePromotion2,
} from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => {
        let locationLink = ''
        let promosImageMobile = null
        let promosImage = null
        if (locationId === '2Ymi2aGR2z310wITFi9C') {
          locationLink = 'https://g.page/sushicalidowntown?share'
          promosImage = promotionsCoquitlam
          promosImageMobile = mobilePromoCoquitlam
        } else if (locationId === 'SQbyqMWAeQ8k1f3zNMoN') {
          locationLink = 'https://goo.gl/maps/8xUC4mZn8bVaztyg9'
          promosImage = promotionsBroadway
          promosImageMobile = mobilePromoBroadway
        } else if (locationId === '5lQdZCLBqcTmq0cXZ8sZ') {
          locationLink = 'https://goo.gl/maps/8xUC4mZn8bVaztyg9'
          promosImage = promotionsSurrey
          promosImageMobile = mobilePromoSurrey
        } else if (locationId === 'oRj2HaNCcEj8TRBU6QlV') {
          locationLink = 'https://goo.gl/maps/8xUC4mZn8bVaztyg9'
          promosImage = promotionsNorthRd
          promosImageMobile = mobilePromoNorthRd
        } else {
          locationLink = 'https://g.page/sushicalivan?share'
          promosImage = promotionsMetrotown
          promosImageMobile = mobilePromoMetrotown
        }
        return (
          <CFView>
            <MobileScreen>
              <CFView column center>
                <CFImage src={promosImageMobile} w="100%" alt="Promotions" />
                <CFImage src={mobilePromotion2} w="100%" alt="Promotions" />
              </CFView>
            </MobileScreen>
            <DefaultScreen>
              <CFView column center m="0 auto" maxWidth="1400px">
                <CFImage src={promosImage} w="100%" alt="Promotions" />
                <CFImage src={promotions2} w="100%" alt="Promotions" />
              </CFView>
            </DefaultScreen>
          </CFView>
        )
      }}
    </CFSelect>
  )
}
