import React from 'react'
import {
  CFView,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import {
  downloadAppButton,
  hero,
  heroText,
  logo,
  mobileHero,
  mobileHeroText,
} from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="85vh"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          justifyBetween
          alignCenter
        >
          <CFView column justifyStart alignCenter h="650px">
            <CFImage src={mobileHeroText} w="100%" alt="About" mb="20px" />
            <CFView column justifyBetween alignCenter ph="10px">
              <CFView mb="5px">
                <OrderPickupButton />
              </CFView>
              <CFLink href="http://onelink.to/daeji">
                <CFImage
                  src={downloadAppButton}
                  maxWidth="300px"
                  alt="Chicko Chicken Download App"
                />
              </CFLink>
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="700px"
          w="100%"
          maxHeight="690px"
          image={`url(${hero}) center / cover no-repeat`}
          zIndex={90}
          relative
        >
          <Header />
          <CFView relative column justifyCenter alignEnd>
            <CFView column center h="600px" mr="50px">
              <CFImage src={heroText} w="550px" alt="About" />
              <CFView mv="20px">
                <OrderPickupButton />
              </CFView>
              <CFLink href="http://onelink.to/daeji">
                <CFImage
                  src={downloadAppButton}
                  maxWidth="330px"
                  alt="Chicko Chicken Download App"
                  hover
                />
              </CFLink>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
