import React from 'react'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  locationMapBroadway,
  locationMapMetrotown,
  locationMapCoquitlam,
  locationMapSurrey,
  locationMapNorthRd,
  mobileMapBroadway,
  mobileMapCoquitlam,
  mobileMapMetrotown,
  mobileMapSurrey,
  mobileMapNorthRd,
} from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => {
        let locationLink = ''
        let mapImageMobile = null
        let mapImage = null
        if (locationId === '2Ymi2aGR2z310wITFi9C') {
          locationLink = 'https://goo.gl/maps/TAxBP6GbZ2MpLNUC7'
          mapImage = locationMapCoquitlam
          mapImageMobile = mobileMapCoquitlam
        } else if (locationId === 'SQbyqMWAeQ8k1f3zNMoN') {
          locationLink = 'https://g.page/Daeji-Vancouver?share'
          mapImage = locationMapBroadway
          mapImageMobile = mobileMapBroadway
        } else if (locationId === '5lQdZCLBqcTmq0cXZ8sZ') {
          locationLink = 'https://goo.gl/maps/Ds9LCWtDgrwE19WM6'
          mapImage = locationMapSurrey
          mapImageMobile = mobileMapSurrey
        } else if (locationId === 'oRj2HaNCcEj8TRBU6QlV') {
          locationLink = 'https://goo.gl/maps/p6vTqP7WKeDtHAU5A'
          mapImage = locationMapNorthRd
          mapImageMobile = mobileMapNorthRd
        } else {
          locationLink = 'https://goo.gl/maps/a76b1h2Sh8vTxmvw6'
          mapImage = locationMapMetrotown
          mapImageMobile = mobileMapMetrotown
        }
        return (
          <CFView>
            <MobileScreen>
              <CFLink href={locationLink}>
                <CFImage
                  src={mapImageMobile}
                  w="100%"
                  alt="Daeji Cutlet House Map"
                />
              </CFLink>
            </MobileScreen>
            <DefaultScreen>
              <CFLink href={locationLink}>
                <CFImage src={mapImage} w="100%" alt="Daeji Cutlet House Map" />
              </CFLink>
            </DefaultScreen>
          </CFView>
        )
      }}
    </CFSelect>
  )
}
